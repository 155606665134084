import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FadeIn from "react-fade-in"

import { FiMail, FiPhoneCall, FiMapPin } from "react-icons/fi"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FormFormik from "../components/contact/form"

const Contact = ({ intl }) => (
  <Layout page="contact">
    <SEO
      lang={intl.locale}
      title={intl.formatMessage({ id: "header.navlink.contact" })}
    />
    <Container fluid>
      <FadeIn>
        <Row>
          <Col lg="4">
            <h1>
              <FormattedMessage id="contact.title" defaultMessage="Title" />
            </h1>
            <p>
              <FormattedMessage id="contact.message" defaultMessage="Message" />
            </p>
            <h4 className="d-flex">
              <FiMail className="align-self-center mr-2" />
              <a href="mailto:krtfa@krtfa.or.kr">krtfa@krtfa.or.kr</a>
            </h4>
            <h4 className="d-flex">
              <FiPhoneCall className="align-self-center mr-2" />
              <a href="tel:07040072123">07040072123</a>
            </h4>
            <h4 className="d-flex">
              <FiMapPin className="align-self-center mr-2" />
              <a href="https://map.kakao.com/?urlX=504721.0&amp;urlY=1129712.0&amp;name=%EC%84%9C%EC%9A%B8%20%EC%A4%91%EA%B5%AC%20%ED%99%A9%ED%95%99%EB%8F%99%20668&amp;map_type=TYPE_MAP&amp;from=roughmap">
                <FormattedMessage
                  id="contact.address"
                  defaultMessage="Address"
                />
              </a>
            </h4>
          </Col>
          <Col lg="8">
            <div className="form-wrapper">
              <h2>
                <FormattedMessage
                  id="contact.form.title"
                  defaultMessage="Title"
                />
              </h2>
              <FormFormik />
            </div>
          </Col>
        </Row>
      </FadeIn>
    </Container>
  </Layout>
)

export default injectIntl(Contact)
