import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import * as yup from "yup"
import { Formik, Field } from "formik"
import Swal from "sweetalert2"

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/

const schema = yup.object({
  fullName: yup
    .string()
    .min(2, "Please enter a valid name.")
    .max(50, "Please enter a valid name.")
    .required("Required."),
  company: yup
    .string()
    .min(2, "Please enter a valid name.")
    .max(50, "Please enter a valid name.")
    .required("Required."),
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Required."),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid.")
    .required("Must enter a phone number."),
  message: yup.string().min(10, "Message too short.").required("Required."),
  productIntro: yup
    .string()
    .min(10, "Message too short.")
    .required("Required."),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const FormFormik = () => (
  <Formik
    validationSchema={schema}
    onSubmit={(values, { setSubmitting }) => {
      fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...values,
        }),
      })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Message has been sent.",
            confirmButtonText: "Finish.",
            confirmButtonColor: "#08B8D6",
          })
          setSubmitting(false)
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            confirmButtonColor: "#08B8D6",
          })
          setSubmitting(false)
        })
    }}
    initialValues={{
      fullName: "",
      company: "",
      email: "",
      phoneNumber: "",
      message: "",
      productIntro: "",
    }}
  >
    {({ handleSubmit, touched, errors, isSubmitting }) => (
      <Form
        name="contact"
        onSubmit={handleSubmit}
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        <input type="hidden" name="bot-field" />
        <Form.Row>
          <Form.Group as={Col} xs="12" md="6" controlid="fullName">
            <Form.Label>
              <FormattedMessage
                id="contact.form.name"
                defaultMessage="Full Name"
              />
            </Form.Label>
            <Field name="fullName" type="text" className="form-control" />
            {touched.fullName && errors.fullName && (
              <p className="danger">{errors.fullName}</p>
            )}
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" controlId="formGridCompany">
            <Form.Label>
              <FormattedMessage
                id="contact.form.company"
                defaultMessage="Company"
              />
            </Form.Label>
            <Field name="company" type="text" className="form-control" />
            {touched.company && errors.company && (
              <p className="danger">{errors.company}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs="12" md="6" controlid="formGridEmail">
            <Form.Label>
              <FormattedMessage
                id="contact.form.email"
                defaultMessage="Email Address"
              />
            </Form.Label>
            <Field name="email" type="email" className="form-control" />
            {touched.email && errors.email && (
              <p className="danger">{errors.email}</p>
            )}
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" controlid="formGridPhoneNumber">
            <Form.Label>
              <FormattedMessage
                id="contact.form.phone"
                defaultMessage="Phone Number"
              />
            </Form.Label>
            <Field name="phoneNumber" type="tel" className="form-control" />
            {touched.phoneNumber && errors.phoneNumber && (
              <p className="danger">{errors.phoneNumber}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Group controlid="formMessage">
          <Form.Label>
            <FormattedMessage
              id="contact.form.message"
              defaultMessage="Message"
            />
          </Form.Label>
          <Field
            name="message"
            component="textarea"
            rows="3"
            className="form-control"
          />
          {touched.message && errors.message && (
            <p className="danger">{errors.message}</p>
          )}
        </Form.Group>
        <Form.Group controlid="formProductIntro">
          <Form.Label>
            <FormattedMessage
              id="contact.form.info"
              defaultMessage="Product Information"
            />
          </Form.Label>
          <Field
            name="productIntro"
            component="textarea"
            rows="3"
            className="form-control"
          />
          {touched.productIntro && errors.productIntro && (
            <p className="danger">{errors.productIntro}</p>
          )}
        </Form.Group>
        <Button
          name="submit"
          type="submit"
          disabled={isSubmitting}
          className="btn-filled"
        >
          <FormattedMessage
            id="contact.form.send"
            defaultMessage="Send Message"
          />
        </Button>
      </Form>
    )}
  </Formik>
)

export default injectIntl(FormFormik)
